import React from "react";

const Page = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-blue-600">
        Добро пожаловать на мою страницу!
        {process.env.REACT_APP_VER} ({process.env.REACT_APP_MODE})
      </h1>
    </div>
  );
};

export default Page;
