import React, { useState, useEffect } from "react";
import axiosInstance from "./api/Axios"; // Import the axiosInstance
import "./App.css";
import Page1 from "./pages/Page1";
//import Archive from "./pages/Archive";
//import LoginForm from "./pages/LoginForm";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  menuClasses,
} from "react-pro-sidebar";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ArchiveIcon from "@mui/icons-material/Archive";

const App = () => {
  const { collapseSidebar, toggleSidebar, broken } = useProSidebar();
  const [theme, setTheme] = useState("light");
  const [hasImage, setHasImage] = useState(false);
  const [darkToggle, setDarkToggle] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true); // Initialize collapsed state
  const location = useLocation();

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axiosInstance.get("/user/me");
        if (response.status === 200) {
          setIsLoggedIn(true);
        }
      } catch (error) {
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []);

  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const themes = {
    light: {
      sidebar: {
        backgroundColor: "#FFFFFF", //backbround color
        color: "#010101", //text color
      },
      menu: {
        menuContent: "#fbfcfd",
        icon: "#010101 ", //icon color
        hover: {
          backgroundColor: "#FFF3EB", //hover highlight color
          color: "#FD6900", //hover text color
        },
        disabled: {
          color: "#9fb6cf",
        },
      },
    },
    dark: {
      sidebar: {
        backgroundColor: "#0b2948",
        color: "#8ba1b7",
      },
      menu: {
        menuContent: "#082440",
        icon: "#59d0ff",
        hover: {
          backgroundColor: "#00458b",
          color: "#b6c8d9",
        },
        disabled: {
          color: "#3e5e7e",
        },
      },
    },
  };

  const menuItemStyles = {
    root: {
      fontSize: "18px",
      fontWeight: 400,
    },
    icon: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: theme === "dark" ? "#b6b7b9" : "#082440",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !isCollapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  //if (!isLoggedIn) {
  //  return <LoginForm setIsLoggedIn={setIsLoggedIn} />;
  //}

  return (
    <div className={`flex h-screen ${darkToggle && "dark"}`}>
      <div className="flex select-none">
        <Sidebar
          rootStyles={{
            color: themes[theme].sidebar.color,
            borderWidth: 0,
          }}
          image={
            hasImage
              ? "https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
              : null
          }
          backgroundColor={hexToRgba(
            themes[theme].sidebar.backgroundColor,
            hasImage ? 0.9 : 1
          )}
          width="280px"
          breakPoint="sm"
          collapsed={isCollapsed} // Pass the collapsed state
        >
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              onClick={() => {
                collapseSidebar();
                setIsCollapsed(!isCollapsed); // Toggle the collapsed state
              }}
              className="mb-10"
              icon={<MenuRoundedIcon />}
            >
              <p className="font-bold">Мира</p>
            </MenuItem>
            <MenuItem
              component={<Link to="/" className="link" />}
              icon={<ChecklistIcon />}
            >
              Личный кабинет
            </MenuItem>
            <MenuItem
              component={<Link to="/p" className="link" />}
              icon={<ArchiveIcon />}
            >
              Архив
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
      <div className="w-full overflow-y-scroll overflow-x-hidden">
        {broken && (
          <div className="fixed top-0 left-0 right-0 m-0 w-full bg-[#FFFFFF] shadow-2xl z-50">
            <IconButton onClick={() => toggleSidebar()}>
              <MenuRoundedIcon />
            </IconButton>
          </div>
        )}
        <Routes>
          <Route path="/" element={<Page1 />} />
          <Route path="/p" element={<Page1 />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
